import { JSEncrypt } from "jsencrypt";

var pangeajsSessionId = window.sessionStorage.getItem("pangeajsSessionId");

initializeSession();

function initializeSession() {
    
    if(!pangeajsSessionId) {
        pangeajsSessionId = createUUID();
        window.sessionStorage.setItem("pangeajsSessionId", pangeajsSessionId);
    }

    loadScript(RISKIFIED_JS + '&sid=' + pangeajsSessionId, () => { console.log('Pangea.js sessionId set: ' + pangeajsSessionId) });        
}

export default class pangea {

    private static tokenUrl: string = TOKEN_URL;
    private static clientSessionDataUrl : string = SESSION_DATA_URL;

    // obsolete in favor of getSessionData()
    public static getSessionId() :string{
        return pangeajsSessionId;
    }

    public static getSessionData(partnerIdentifier: string) : Promise<string> {
        
        let requestId = Math.random().toString(11).replace('0.', '');

        return this.getClientSessionData(partnerIdentifier, requestId)
            .then(function (sessionData) {
                return btoa(JSON.stringify({ipAddress: sessionData.clientIp, sessionId: pangeajsSessionId}));
            })
            .catch(error => {
                console.log('getSessionData error: ' + error + '\nRequestId: ' + requestId);
                return btoa(JSON.stringify({ipAddress: null, sessionId: pangeajsSessionId}));
            });
    }

    public static createToken(card: CardInformation): Promise<TokenResponse> {

        if (!isCardNumberValid(card.cardNumber)) {
            console.log("cardNumber is not a valid card number");
            return Promise.reject('cardNumber is not a valid card number');
        }

        let requestId = Math.random().toString(11).replace('0.', '');

        return this.getToken(card, requestId)
            .catch(error => {
                console.log(error + '\nRequestId: ' + requestId);
                return Promise.reject(error);
            });
    }

    private static getClientSessionData(partnerIdentifier: string, requestId: string)
        : Promise<ClientSessionData> {
            
            return fetch(this.clientSessionDataUrl + '?partnerIdentifier=' + partnerIdentifier + '&requestId='+requestId, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'x-pangea-user-agent': SDK_USER_AGENT
                },
            })
            .then(async response => {
                if (!response.ok) {
                    throw new Error(response.statusText + ': ' + await response.text());
                }
    
                return response.json() as Promise<ClientSessionData>;
            });
        }

    private static getToken(card: CardInformation, requestId: string)
        : Promise<TokenResponse> {

        var data = {
            encryptedCardNumber: this.encrypt(card.publicKey, card.cardNumber),
            encryptedCvv: this.encrypt(card.publicKey, card.cvv),
            partnerIdentifier: card.partnerIdentifier,
            requestId: requestId
        };

        return fetch(this.tokenUrl, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'x-pangea-user-agent': SDK_USER_AGENT
            },
            body: JSON.stringify(data)
        })
        .then(async response => {
            if (!response.ok) {
                console.log('tokenization response is not ok');
                throw new Error(response.statusText + ': ' + await response.text());
            }

            return response.json() as Promise<TokenResponse>;
        });
    }

    private static encrypt(publicKey: string, data: string): string {
        var encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);
        var encrypted = encrypt.encrypt(data);
        return encrypted;
    }
}

function isCardNumberValid(cardNumber: string) {
    const numbers = cardNumber
        .replace(/\D+/g, '')
        .split('')
        .reverse()
        .map(Number);
    
        if (!numbers.length || numbers.length < 12) {
        return false;
    }

    let total = 0;
    for (let i = 0; i < numbers.length; i++) {
        total += i % 2 ? 2 * numbers[i] - (numbers[i] > 4 ? 9 : 0) : numbers[i];
    }

    return total % 10 === 0;
}

function createUUID(): string {
    // http://www.ietf.org/rfc/rfc4122.txt
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((parseInt(s[19], 10) & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    var uuid = s.join("");
    return uuid;
}

function loadScript(url: string, callback: Function){

    var script = document.createElement("script")
    script.type = "text/javascript";

    script.onload = function(){
        callback();
    };

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
}

class CardInformation {
    publicKey!: string;
    partnerIdentifier!: string;
    cardNumber!: string;
    cvv!: string;
}

class TokenResponse {
    token!: string;
}

class ClientSessionData {
    clientIp!: string;
}